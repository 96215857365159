import { useSelector } from "react-redux";
import content from "../../content/content";
import History from "./History/History";
import aboutImg from "../../assets/about/AboutImg.png"
import "./About.scss";

const About = () => {
  const { currentLanguage } = useSelector((state) => state.languages);
  return (
    <div className="about">
      <div className="about-img">
        <h1 className="title">{content[currentLanguage].ABOUT_LINK_TITLE}</h1>
        <img src={aboutImg} alt="about" />
      </div>

      <History />
    </div>
  );
};

export default About;
