import { useSelector } from "react-redux";
import patternFull from "../../../assets/PatternFull.png";
import content from "../../../content/content";
import "./History.scss";

const History = () => {
  const { currentLanguage } = useSelector((state) => state.languages);
  return (
    <>
      <section className="history">
        <div className="history-text-wrapper">
          <h4 className="history-title">
            {content[currentLanguage].HISTORY_TITLE}
          </h4>
          <p className="history-text" style={{ whiteSpace: "pre-wrap" }}>
            {content[currentLanguage].HISTORY_TEXT_1}
          </p>
          <p className="history-text">
            {content[currentLanguage].HISTORY_TEXT_2}
          </p>
          <p className="history-text" style={{ whiteSpace: "pre-wrap" }}>
            {content[currentLanguage].HISTORY_TEXT_3}
          </p>
        </div>
      </section>
      <section className="additional-history">
        <h4 className="additional-text__title">
          {content[currentLanguage].ADDITIONAL_HISTORY_TITLE}
        </h4>
        <p className="history-text">
          {content[currentLanguage].ADDITIONAL_HISTORY_TEXT_1}
          <span className="history-text_bold">
            {content[currentLanguage].ADDITIONAL_HISTORY_TEXT_1_BOLD}
          </span>
        </p>
        <p className="history-text">
          {content[currentLanguage].ADDITIONAL_HISTORY_TEXT_2}
        </p>
        <p className="history-text">
          {content[currentLanguage].ADDITIONAL_HISTORY_TEXT_3}
        </p>
        <div className="history-hashtag">
          {content[currentLanguage].HISTORY_HASHTAG}
        </div>
        {/* <img className="pattern-full" src={patternFull} alt="" /> */}
      </section>
    </>
  );
};

export default History;
