import React from 'react'
import { Outlet } from 'react-router-dom'
import ProjectsComponent from '../../../components/ProjectsComponent'

const ProjectLayout = () => {
    return (
        <>
            <Outlet />
            <ProjectsComponent />
        </>
    )
}

export default ProjectLayout