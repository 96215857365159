import React from "react";
import { NavLink } from "react-router-dom";
import telegram from "../../assets/socials/telegram.png";
import instagram from "../../assets/socials/instagram.png";
import facebook from "../../assets/socials/facebook.png";
import { useSelector } from "react-redux";
import content from "../../content/content";
import "./Footer.scss";

const Footer = () => {
  const { currentLanguage } = useSelector((state) => state.languages);

  return (
    <footer className="footer">
      <div className="footer-title">
        <h3>Світ майбутнього</h3>
        <h4>Благодійний фонд</h4>
      </div>
      <div className="footer-content">
        <div className="footer-lists">
          <ul className="footer-list">
            <li className="footer-item">
              <NavLink className={"footer-link"} to="/">
                {content[currentLanguage].MAIN_LINK_TITLE}
              </NavLink>
            </li>
            <li className="footer-item">
              <NavLink className={"footer-link"} to="/help">
                {content[currentLanguage].HOW_TO_HELP_LINK_TITLE}
              </NavLink>
            </li>
            <li className="footer-item">
              <NavLink className={"footer-link"} to="/projects">
                {content[currentLanguage].PROJECTS_LINK_TITLE}
              </NavLink>
            </li>
            <li className="footer-item">
              <NavLink className={"footer-link"} to="/blog">
                {content[currentLanguage].BLOG_LINK_TITLE}
              </NavLink>
            </li>
            <li className="footer-item">
              <NavLink className={"footer-link"} to="/gallery">
                {content[currentLanguage].GALLERY_LINK_TITLE}
              </NavLink>
            </li>
          </ul>
          <ul className="footer-list">
            <li className="footer-item">
              <NavLink className={"footer-link"} to="/team">
                {content[currentLanguage].TEAM_LINK_TITLE}
              </NavLink>
            </li>
            <li className="footer-item">
              <NavLink className={"footer-link"} to="/partners">
                {content[currentLanguage].PARTNERS_LINK_TITLE}
              </NavLink>
            </li>
            <li className="footer-item">
              <NavLink className={"footer-link"} to="/reports">
                {content[currentLanguage].REPORTS_LINK_TITLE}
              </NavLink>
            </li>
            <li className="footer-item">
              <NavLink className={"footer-link"} to="/contacts">
                {content[currentLanguage].CONTACTS_LINK_TITLE}
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="contact-info">
          <div className="phone">+38 093 630 7777</div>
          <div className="email">info@worldoffuture.org</div>
          <div className="address">
            вул. Головна, 34
            <br /> Чернівці, Україна
          </div>
        </div>
      </div>
      <div className="footer-social">
        <div className="socials">
          <a className="social" href="https://t.me/worldoffutureua">
            <img className="social-icon" src={telegram} alt="" />
          </a>
          <a
            className="social"
            href="https://instagram.com/wof_ukraine?igshid=YmMyMTA2M2Y="
          >
            <img className="social-icon" src={instagram} alt="" />
          </a>
          <a className="social" href="https://www.facebook.com/wofukraine/">
            <img className="social-icon" src={facebook} alt="" />
          </a>
        </div>
        <div className="footer-copyright">© Світ Майбутнього, 2022</div>
      </div>
    </footer>
  );
};

export default Footer;
