import { api } from "../api";

const projectsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getProjects: builder.query({
            query: () => '/projects',
            providesTags: ["Projects"]
        }),
        getProject: builder.query({
            query: projectId => `/projects/${projectId}`
        }),
        addProject: builder.mutation({
            query: projectData => ({
                url: "/projects",
                method: 'POST',
                body: projectData
            }),
            invalidatesTags: ["Projects"]
        }),
        deleteProject: builder.mutation({
            query: projectId => ({
                url: `/projects/${projectId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Projects"]
        }),
        editProject: builder.mutation({
            query: ({ projectId, projectData }) => ({
                url: `/projects/${projectId}`,
                method: "PUT",
                body: projectData
            }),
            invalidatesTags: ["Projects"]
        }),
    })
})

export const {
    useGetProjectsQuery,
    useGetProjectQuery,
    useAddProjectMutation,
    useDeleteProjectMutation,
    useEditProjectMutation
} = projectsApi