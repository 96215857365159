import { api } from "../api"

const galleryApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getGalleryCategories: builder.query({
            query: () => '/galleryCategory',
            providesTags: ["GalleryCategory"]
        }),
        getGalleryCategory: builder.query({
            query: galleryId => `/galleryCategory/${galleryId}`
        }),
        addGalleryCategory: builder.mutation({
            query: galleryData => ({
                url: "/galleryCategory",
                method: 'POST',
                body: galleryData
            }),
            invalidatesTags: ["GalleryCategory"]
        }),
        deleteGalleryCategory: builder.mutation({
            query: galleryId => ({
                url: `/galleryCategory/${galleryId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["GalleryCategory"]
        }),
        editGalleryCategory: builder.mutation({
            query: ({ galleryId, galleryData }) => ({
                url: `/galleryCategory/${galleryId}`,
                method: "PUT",
                body: galleryData
            }),
            invalidatesTags: ["GalleryCategory"]
        }),

    })
})

export const {
    useGetGalleryCategoriesQuery,
    useGetGalleryCategoryQuery,
    useAddGalleryCategoryMutation,
    useDeleteGalleryCategoryMutation,
    useEditGalleryCategoryMutation
} = galleryApi