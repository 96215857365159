import content from "../../../content/content";
import Logo from "../../../assets/Logo.png";
import Cross from "../../../assets/Cross.png";

import NavigationItem from "./NavigationItem";
import { useEffect } from "react";
import "./Navigation.scss";

const Navigation = ({
  isMenuOpen,
  detectClick,
  toggleMenu,
  currentLanguage,
}) => {
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isMenuOpen]);
  return (
    <div
      onClick={(e) => detectClick(e)}
      className={`navigation-wrapper ${isMenuOpen ? "open" : "closed"}`}
    >
      <nav className={`navigation`}>
        <ul className="navigation-list">
          <li className="navigation-head">
            <img src={Logo} alt="logo" className="logo" />
            <button onClick={toggleMenu} className="close-btn">
              <img src={Cross} alt="close-icon" />
            </button>
          </li>
          <NavigationItem
            text={content[currentLanguage].MAIN_LINK_TITLE}
            toggleMenu={toggleMenu}
            url="/"
          />
          <NavigationItem
            text={content[currentLanguage].NEED_HELP_LINK_TITLE}
            toggleMenu={toggleMenu}
            url="need-help"
          />
          <NavigationItem
            text={content[currentLanguage].HOW_TO_HELP_LINK_TITLE}
            toggleMenu={toggleMenu}
            url="help"
          />
          <NavigationItem
            text={content[currentLanguage].NEWS_LINK_TITLE}
            toggleMenu={toggleMenu}
            url="news"
          />
          <NavigationItem
            text={content[currentLanguage].PROJECTS_LINK_TITLE}
            toggleMenu={toggleMenu}
            url="projects"
          />
          <NavigationItem
            text={content[currentLanguage].TEAM_LINK_TITLE}
            toggleMenu={toggleMenu}
            url="team"
          />
          <NavigationItem
            text={content[currentLanguage].ABOUT_LINK_TITLE}
            toggleMenu={toggleMenu}
            url="about"
          />
          <NavigationItem
            text={content[currentLanguage].REPORTS_LINK_TITLE}
            toggleMenu={toggleMenu}
            url="reports"
          />
          <NavigationItem
            text={content[currentLanguage].GALLERY_LINK_TITLE}
            toggleMenu={toggleMenu}
            url="gallery"
          />
          <NavigationItem
            text={content[currentLanguage].PARTNERS_LINK_TITLE}
            toggleMenu={toggleMenu}
            url="partners"
          />
          <NavigationItem
            text={content[currentLanguage].BLOG_LINK_TITLE}
            toggleMenu={toggleMenu}
            url="blog"
          />
          <NavigationItem
            text={content[currentLanguage].CONTACTS_LINK_TITLE}
            toggleMenu={toggleMenu}
            url="contacts"
          />
          <li className="stronger-together">#разом сильніші</li>
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
