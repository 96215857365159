import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./TogetherPoints.module.scss";

export const cities = [
  {
    eng: "Chernivtsi",
    ua: "м. Чернівці",
    redirectTo: "Chernivtsi",
  },
  {
    eng: "Chortkiv",
    ua: "м. Чортків",
    redirectTo: "Chortkiv",
  },
  {
    eng: "Ivano-Frankivsk",
    ua: "м. Івано-Франківськ",
    redirectTo: "IvanoFrankivsk",
  },
  {
    eng: "Kalush",
    ua: "м. Калуш",
    redirectTo: "Kalush",
  },
  {
    eng: "Shumsk",
    ua: "м. Шумськ",
    redirectTo: "Shumsk",
  },
  {
    eng: "Storojinets",
    ua: "м. Сторожинець",
    redirectTo: "Storojinets",
  },
  {
    eng: "Ternopil",
    ua: "м. Тернопіль",
    redirectTo: "Ternopil",
  },
  {
    eng: "Tyachiv",
    ua: "м. Тячів",
    redirectTo: "Tyachiv",
  },
  {
    eng: "Uzhorod, str. Rakotci",
    ua: "м. Ужгород, Ракоці",
    redirectTo: "UzhorodRakotci",
  },
  {
    eng: "Uzhorod, str. Shvabska",
    ua: "м. Ужгород, вул. Швабська",
    redirectTo: "UzhorodShvabska",
  },
];

const TogetherPoints = () => {
  const { currentLanguage } = useSelector((state) => state.languages);

  return (
    <div className={styles.togetherPoints}>
      <div className={styles.titleWrapper}>
        <h1 className={`title ${styles.title}`}>Галерея:</h1>
        <h2 className={styles.subtitle}>Дитячі точки СПІЛЬНО</h2>
      </div>
      <div className={styles.cities}>
        {cities.map((city, index) => (
          <Link to={city.redirectTo} className={styles.city} key={index}>
            {city[currentLanguage]}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default TogetherPoints;
