import { useSelector } from "react-redux";
import { projectEnteties } from "./ProjectEnteties/constants";
import styles from "./ProjectsComponent.module.scss"
import { Link } from "react-router-dom";
import { useGetProjectsQuery } from "../../redux/api/projectsApi";

const projectsFromDataToSkip = [
  "63b80c73740cfb372dfd5b46",
  "63b80dc7740cfb372dfd5b75",
  "63b80e20740cfb372dfd5b93",
  "63b80e4a740cfb372dfd5b9e",
]

const ProjectsComponent = () => {
  const { currentLanguage } = useSelector((state) => state.languages);
  const {data} = useGetProjectsQuery();

  console.log(data);

  const projects = data?.filter((project) => !projectsFromDataToSkip.includes(project._id))


  return <div className={styles.projects}>
    <h2 className={`title ${styles.title}`}>Наші проекти</h2>
    <div className={styles.project}>
      <div className={styles.projectImage}>
        <img src={projectEnteties.socialAndPsychologicalWork.img} alt="" />
      </div>
      <div className={styles.projectInfo}>
        <div className={styles.projectTitle}>{projectEnteties.socialAndPsychologicalWork[currentLanguage].title}</div>
        <div className={styles.projectDesc}>{projectEnteties.socialAndPsychologicalWork[currentLanguage].description}</div>
        <Link to="/projects/63b80c73740cfb372dfd5b46" className={styles.btnMore}>Більше</Link>
      </div>
    </div>

    <div className={styles.projectWrapper}>
      {projects?.map((project) => {
        console.log(project);
        return <div className={styles.project}>
          <div className={styles.projectImage}>
            <img src={project?.img?.url} alt="" />
          </div>
          <div className={styles.projectInfo}>
            <div className={styles.projectTitle}>{project?.translations[currentLanguage]?.name}</div>
            <div className={styles.projectDesc}>{project?.translations[currentLanguage]?.miniDesc}</div>
            <Link to={`/projects/${project._id}`} className={styles.btnMore}>Більше</Link>
          </div>
        </div>
      }
      )}
    </div>


    <h3>за підтримки Unicef</h3>
    <div className={styles.row}>
      <div className={styles.project}>
        <div className={styles.projectImage}>
          <img src={projectEnteties.togetherPoints.img} alt="" />
        </div>
        <div className={styles.projectInfo}>
          <div className={styles.projectTitle}>{projectEnteties.togetherPoints[currentLanguage].title}</div>
          <div className={styles.projectDesc}>{projectEnteties.togetherPoints[currentLanguage].description}</div>
          <Link to="/projects/together" className={styles.btnMore}>Більше</Link>
        </div>
      </div>
      <div className={styles.project}>
        <div className={styles.projectImage}>
          <img src={projectEnteties.peopleAssistance.img} alt="" />
        </div>
        <div className={styles.projectInfo}>
          <div className={styles.projectTitle}>{projectEnteties.peopleAssistance[currentLanguage].title}</div>
          <div className={styles.projectDesc}>{projectEnteties.peopleAssistance[currentLanguage].description}</div>
          <Link to="/projects/63b80dc7740cfb372dfd5b75" className={styles.btnMore}>Більше</Link>
        </div>
      </div>
    </div>


    <h2 className={`title ${styles.title}`}>Наші цілі</h2>
    <div className={styles.row}>
      <div className={styles.project}>
        <div className={styles.projectImage}>
          <img src={projectEnteties.familyHouse.img} alt="" />
        </div>
        <div className={styles.projectInfo}>
          <div className={styles.projectTitle}>{projectEnteties.familyHouse[currentLanguage].title}</div>
          <div className={styles.projectDesc}>{projectEnteties.familyHouse[currentLanguage].description}</div>
          <Link to="/projects/63b80e20740cfb372dfd5b93" className={styles.btnMore}>Більше</Link>
        </div>
      </div>
      <div className={styles.project}>
        <div className={styles.projectImage}>
          <img src={projectEnteties.prostheticsCenter.img} alt="" />
        </div>
        <div className={styles.projectInfo}>
          <div className={styles.projectTitle}>{projectEnteties.prostheticsCenter[currentLanguage].title}</div>
          <div className={styles.projectDesc}>{projectEnteties.prostheticsCenter[currentLanguage].description}</div>
          <Link to="/projects/63b80e4a740cfb372dfd5b9e" className={styles.btnMore}>Більше</Link>
        </div>
      </div>
    </div>


  </div >
};

export default ProjectsComponent;
