import prostheticsCenterImg from "../assets/prostheticsCenter.png"
import socialAndPsychologicalWorkImg from "../assets/socialAndPsychologicalWork.png"
import togetherPointsImg from "../assets/togetherPoints.png"
import peopleAssistanceImg from "../assets/peopleAssistance.png"
import familyHouseImg from "../assets/familyHouse.png"

export const projectEnteties = {
    prostheticsCenter: {
        eng: {
            img: "https://i.ibb.co/0jX5QqH/Prosthetics-Center.png",
            title: "Center for prosthetics",
            description: "We work every day to ensure that every child physically injured as a result of the war does not feel 'different', so we are creating a prosthetics and rehabilitation center",
        },
        ua: {
            img: "https://i.ibb.co/0jX5QqH/Prosthetics-Center.png",
            title: "Центр протезування",
            description: `Ми щодня працюємо над тим, щоб кожна дитина, яка фізично постраждала внаслідок війни, не відчувала себе “іншою”, тому ми створюємо центр протезування та реабілітації`,
        },
        img: prostheticsCenterImg,
    },
    socialAndPsychologicalWork: {
        eng: {
            img: "https://i.ibb.co/0jX5QqH/Prosthetics-Center.png",
            title: "Social and psychological work",
            description: `The foundation has a psychological center staffed by professional and experienced psychologists. They constantly conduct trainings for education workers, consultations and activities for children and their families.`,
        },
        ua: {
            img: "https://i.ibb.co/0jX5QqH/Prosthetics-Center.png",
            title: "Соціально-психологічна робота",
            description: `На базі фонду діє психологічний центр, команду якого формують професійні та досвідчені психологи. 
            Фахівці здійснюють соціально-психологічну роботу в груповому та індивідуальному форматі (тренінги для працівників освіти, психологічні заходи для дітей і їх сімей, а також консультації з особами різних вікових категорій)`,
        },
        img: socialAndPsychologicalWorkImg,
    },
    togetherPoints: {
        eng: {
            img: "https://i.ibb.co/0jX5QqH/Prosthetics-Center.png",
            title: "MHPSS on points TOGETHER",
            description: "MHPSS provides psychosocial support to children and their families and improves the quality of mental health. It is implemented at children's points TOGETHER by conducting psychological activities (training for children and adults, art therapy classes), humanitarian aid and the 'Toy Library' project.",
        },
        ua: {
            img: "https://i.ibb.co/0jX5QqH/Prosthetics-Center.png",
            title: "MHPSS на точках СПІЛЬНО",
            description: `MHPSS передбачає надання психосоціальної допомоги дітям та їх сім’ям і покращення якості психічного здоров’я. Реалізовується на дитячих точках СПІЛЬНО шляхом проведення психологічних заходів (тренінги для дітей та дорослих, арт-терапевтичні заняття), гуманітарною допомогою та проєктом “Бібліотека іграшок”.`,
        },
        img: togetherPointsImg,
    },
    peopleAssistance: {
        eng: {
            img: "https://i.ibb.co/0jX5QqH/Prosthetics-Center.png",
            title: "People assistance",
            description: "Every day we try to help everyone who needs it - children, families of forced migrants, medical institutions and other foundations.",
        },
        ua: {
            img: "https://i.ibb.co/0jX5QqH/Prosthetics-Center.png",
            title: "Допомога населенню",
            description: `Ми щодня намагаємось допомагати кожному, хто цього потребує - діти, сім’ї вимушених переселенців, медичні заклади та інші фонди.`,
        },
        img: peopleAssistanceImg,
    },
    familyHouse: {
        eng: {
            img: "https://i.ibb.co/0jX5QqH/Prosthetics-Center.png",
            title: "Family house",
            description: "The big dream of every employee of our foundation is to create a family-type home so that every child who unfortunately lost a parent in the war can feel prosperous, safe and cared for",
        },
        ua: {
            img: "https://i.ibb.co/0jX5QqH/Prosthetics-Center.png",
            title: "Будинок сімейного типу",
            description: `Великою мрією кожного працівника нашого фонду є створення будинку сімейного типу для того, щоб кожна дитина, яка на жаль, втратила батьків на війні, змогла відчути себе у достатку, безпеці та під опікою.`,
        },
        img: familyHouseImg,
    }
}