import { useSelector } from "react-redux";
import content from "../../content/content";
import errorLeft from "../../assets/errors/errorLeft.png";
import errorRight from "../../assets/errors/errorRight.png";
import "./Errors.scss";

const ServerError = () => {
    console.log("ServerError");
  const { currentLanguage } = useSelector((state) => state.languages);
  return (
    <div className="error-page">
      <section className="error">
        <img src={errorLeft} className="error-left-pattern" />
        <img src={errorRight} className="error-right-pattern" />
        <h2 className="error-title title">
          {content[currentLanguage].SERVER_ERROR_TITLE}
        </h2>
        <p className="error-text">
          {content[currentLanguage].SERVER_ERROR_TEXT}
          <br/>
          <span className="error-text__bold">
            {content[currentLanguage].SERVER_ERROR_TEXT_BOLD}
          </span>
        </p>

        <a className="btn" href="https://pay.fondy.eu/s/ClTDdXb9aIYfY4">
          {content[currentLanguage].PAYMEMT_BTN}
        </a>
      </section>
    </div>
  );
};

export default ServerError;
