
import { createSlice } from '@reduxjs/toolkit';

export const languagesSlice = createSlice({
    name: "languages",
    initialState: {
        currentLanguage: "ua"
    },
    reducers: {
        setLastLanguage: (state) => {
            state.currentLanguage = JSON.parse(localStorage.getItem('language')) || "ua"
        },
        setLanguage: (state, action) => {
            state.currentLanguage = action.payload
            localStorage.setItem('language', JSON.stringify(action.payload))
        }
    }
})

export const { setLanguage, setLastLanguage } = languagesSlice.actions

export default languagesSlice.reducer