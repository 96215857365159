const content = {
  ua: {
    COMING_SOON: "Незабаром",
    //Header
    PROJECTS_LINK_TITLE: "Наші проєкти",
    TEAM_LINK_TITLE: "Наша команда",
    CONTACTS_LINK_TITLE: "Контакти",
    NEED_HELP_LINK_TITLE: "Потрібна\nдопомога",
    MAIN_LINK_TITLE: "Головна",
    ABOUT_LINK_TITLE: "Про нас",
    HOW_TO_HELP_LINK_TITLE: "Долучитись",
    PARTNERS_LINK_TITLE: "Партнери",
    REPORTS_LINK_TITLE: "Звіти",
    GALLERY_LINK_TITLE: "Галерея",
    BLOG_LINK_TITLE: "Блог",
    NEWS_LINK_TITLE: "Новини",

    //Main Page
    //Hero
    HERO_HASHTAG: "#РАЗОМ\nСИЛЬНІШІ",
    HERO_IMG_PRIVACY: `* «…Винятком із загального правила, коли не потрібно отримувати згоду, є зйомка у публічних місцях:  на вулиці, на зборах, конференціях, мітингах та інших заходах публічного характеру.» 
Джерело: сайт Міністерство Юстицій «Правила знімання: коли потрібен дозвіл , а коли ні»`,
    PAYMEMT_BTN: "Допомогти",

    //History
    HISTORY_TITLE: "Ми - українці!",
    HISTORY_TEXT_1: `І це вже багато, що означає.
До 24.02 кожен з нас займався своїм життям. Хтось займався бізнесом,
хтось фотографував красу, хтось проводив тренінги і консультації, а
хтось взагалі за кордоном будував свою кар’єру.`,
    HISTORY_TEXT_2: `За таким сумним збігом, ми зустрілись всі в Чернівцях і почали
            допомогати переселенцям, міста яких були під ударами ворожих ракет
            або вже й взагалі зруйновані. Ми швидко налаштували кухню і почали
            годувати цих людей, потім до нас почали підключатись
            бізнес-партнери. А далі - був прийом великих партій дитячого
            харчування, одягу, медичних препаратів від друзів з-за кордону.
            Точково закривавши запити знайомих і друзів, ми перейшли на
            повноцінний прийом і видачу гуманітарної допомоги.`,
    HISTORY_TEXT_3: `Але всеодно цього було мало…
Багато хто просто не в змозі пробувати будувати інше життя після
отриманих втрат і травм. Тому, на базі фонду ми відкрили
Психологічний центр і почали безкоштовне консультування. Просто
говорили з людьми, просто давали їм сенси, надію і віру в майбутнє.
Але й цього було мало… бо в місто почала приїжджати неймовірна
кількість дітей….`,
    ADDITIONAL_HISTORY_TITLE: "Зараз",
    ADDITIONAL_HISTORY_TEXT_1: `Зараз ми працюємо над адаптацією дітей в умовах війни, надаємо
          консультування сім’ям та допомагаємо дитячим притулкам із організацією
          дозвілля для діток. Гуманітарний штаб досі працює, але основний
          профіль нашого фонду сьогодні саме в`,
    ADDITIONAL_HISTORY_TEXT_1_BOLD: ` соціальній адаптації і психологічному консультуванні дітей, особливо
            тих, які втратили батьків на війні.`,
    ADDITIONAL_HISTORY_TEXT_2: `Ми дуже мріємо побудувати притулок для дітей-сиріт та створити Центр
          протезування, де дітки з інвалідністю зможуть отримати все необхідне,
          що стосується протезування і проходження реабілітації.`,
    ADDITIONAL_HISTORY_TEXT_3: `Майбутнє України в наших із вами руках, тому ми готові докладати
          надзусилля, щоб «діти війни» змогли стати гарними, впевненими в собі,
          успішними «дорослими», якими захоплюється ввесь світ`,
    HISTORY_HASHTAG: "#разомсильніші🤍",

    //Activity
    ACTIVITY_TITLE: ` За 1 рік діяльності:`,
    BUBLE1_SMALL: "близько",
    BUBLE1_AMOUNT: "35",
    BUBLE1_OBJECT: "закладів",
    BUBLE1_ACTION: "отримали підтримку у медичних засобах",

    BUBLE2_SMALL: "більше",
    BUBLE2_AMOUNT: "9000",
    BUBLE2_ACTION: "нагодованих сімей",

    BUBLE3_SMALL: "близько",
    BUBLE3_AMOUNT: "40",
    BUBLE3_OBJECT: "партнерів",
    BUBLE3_ACTION: "допомогли з пошуком ресурсів",

    BUBLE4_AMOUNT: "87",
    BUBLE4_OBJECT: "тонн",
    BUBLE4_ACTION: "гуманітарної допомоги отримано і відправлено в гарячі точки та деокуповані міста України",

    BUBLE5_SMALL: "близько",
    BUBLE5_AMOUNT: "95",
    BUBLE5_OBJECT: "тисяч дітей",
    BUBLE5_ACTION: "отримали психологічну допомогу",

    //Help Page
    BANK_DETAILS_TITLE: "РЕКВІЗИТИ",
    MANUALLY_TITLE: "ВВести вручну:",
    QR_TITLE: "qr-код:",
    GIVE_SMILE_TITLE: "або ПОДАРУВАТИ МИТТЄВУ ПОСМІШКУ МАЛЮКУ:",
    GIVE_SMILE_SUBTITLE: "*плюсик до карми гарантований",
    HOW_HELP_TITLE: "Як допомогти",
    HOW_HELP_TITLE_CONTINUE: "маленьким українцям стати щасливіше?",
    OPTION_BTN: "заповнити заявку",
    OPTION1_DESC: "Якщо ви хочете волонтерити та приносити щастя переселенцям своїми діями ",
    OPTION1_BOLD: "як волонтер",
    OPTION2_DESC: "Якщо ви хочете допомагати маленьким українцям справитись з тривожністю, покращувати їх емоційний стан ",
    OPTION2_BOLD: "як психолог",
    OPTION3_DESC: "Якщо ви не маєте змоги допомагати фізично, але також хочете зробити вклад у безтурботне дитинство українців ",
    OPTION3_BOLD: "як партнер",

    //Projects Page
    PROJECTS_TITLE: "Наші проєкти:",
    PROJECTS_DETAILS_BTN: "Більше",

    //TeamPage
    TEAM_TITLE: "Наша команда",
    PSYCHOLOGISTS_TITLE: "Колектив медико-психологічного центру фонду",
    PSYCHOLOGISTS_CARD_BTN: "Блог",

    //Partners Page
    PARTNERS_TITLE: "Наші партнери:",
    GRATITUDE_TITLE: "Подяка партнерам",
    GRATITUDE_TEXT0: "Всі наші здобутки і досягнення були б неможливими, без люд ей, які постійно відгукувалися і допомагали нам в кожному проекті.",
    GRATITUDE_TEXT1: "Тому, ми хочемо ЩИРО і СЕРДЕЧНО подякувати нашим партнерам та меценатам, які ділилися ресурсами, надсилали кошти і допомагали нам втілити наші ідеї в життя.",
    GRATITUDE_TEXT2: "Завдяки вам, ми можемо працювати і робити ще більше добра для маленьких українців! Завдяки вам, ми й самі продовжуємо вірити в це ДОБРО! Завдяки вам, в нас немає ненависті, а є тільки сильна віра в краще! І в Україну!",
    GRATITUDE_TEXT3: "Бо те, що зараз творить нація українців надихає не тільки ввесь світ, а й нас самих.",
    GRATITUDE_THANKS: "ДЯКУЄМО!",
    GRATITUDE_MORE: "Далі - більше!❤️",

    //Reports Page
    REPORTS_TITLE: "Звіти",
    REPORTS_GALLERY_TITLE: "Галерея",
    REPORTS_YEAR: " Рік",

    //Gallery Page
    GALLERY_TITLE: "Галерея",
    GALLERY_MORE_BTN: "Більше",

    //Blog Page
    BLOG_TITLE: "Блог:",
    BLOG_BTN: "Читати",

    //Contacts Page
    CONTACTS_TITLE: "Контакти:",
    CURRICULUM_TITLE: "ГРАФІК РОБОТИ:",
    CURRICULUM_VALUE: "ПОНЕДІЛОК-П’ЯТНИЦЯ\nз 10: 00 до 18: 00",
    PHONE_TITLE: "НОМЕР ТЕЛЕФОНУ:",
    PHONE_VALUE: "+38 093 630 7777",
    ADDRESS_TITLE: "АДРЕСА:",
    ADDRESS_VALUE: "Вул. Головна, 34",
    EMAIL_TITLE: "ПОШТА:",
    EMAIL_VALUE: "INFO@WORLDOFFUTURE.ORG",
    DETAILS_TITLE: "РЕКВІЗИТИ:",
    DETAILS_VALUE: "UA863562820000026002051534284\nБФ СВІТ МАЙБУТНЬОГО БО\nКод отримувача: 42419304",

    //News Title
    NEWS_TITLE: "Новини",
    MORE_NEWS_BUTTON: "Завантажити більше новин",
    NEWS_CARD_BTN: "Читати більше",
    NEWS_CARD_BTN_CLOSE: "Закрити",

    //Need Help Page
    NEED_HELP_TITLE: "ПОТРІБНА\nДОПОМОГА?",
    NEED_HELP_TEXT1_BLUE: "Якщо ви або ваші рідні",
    NEED_HELP_TEXT1_FIRST: "були змушені переїхати в інший регіон країни внаслідок російської агресії і",
    NEED_HELP_TEXT1_BOLD: " вам потрібна будь-яка гуманітарна допомога,",
    NEED_HELP_TEXT1_SECOND: " заповнюйте нашу заявку нижче",
    FILL_BTN: "ЗАПОВНИТИ ЗАЯВКУ",

    NEED_HELP_TEXT2_BLUE: "Якщо вам або вашій сім‘ї",
    NEED_HELP_TEXT2_BOLD: " потрібна професійна підтримка та психологічна допомога, ",
    NEED_HELP_TEXT2_FIRST: "звертайтесь до наших психологів нижче",


    //Not Found Page
    NOT_FOUND_TITLE: "УПС...",
    NOT_FOUND_TEXT: "Сторінка, яку ви шукаєте, не існує або була видалена.",
    NOT_FOUND_TEXT2: "Поверніться на головну сторінку чи подаруйте дитині посмішку та віру у щасливе життя",
    NOT_FOUND_TEXT2_BOLD: " за допомогою вашого донату",
    NOT_FOUND_BTN: "Повернутися на головну",
    ERROR_LINK: "На головну",

    //Server Error Page
    SERVER_ERROR_TITLE: "Помилка...",
    SERVER_ERROR_TEXT: "На жаль сталася помилка і сервер не відповідає, спробуйте будь ласка пізніше. А поки подаруйте дитині посмішку та віру у щасливе життя",
    SERVER_ERROR_TEXT_BOLD: " за допомогою вашого донату",
  },
  eng: {
    COMING_SOON: "SOON",

    //Header
    PROJECTS_LINK_TITLE: "Projects",
    TEAM_LINK_TITLE: "Team",
    CONTACTS_LINK_TITLE: "Contacts",
    NEED_HELP_LINK_TITLE: "NEED HELP",
    MAIN_LINK_TITLE: "Home",
    ABOUT_LINK_TITLE: "About Us",
    HOW_TO_HELP_LINK_TITLE: "Join Us",
    PARTNERS_LINK_TITLE: "Partners",
    REPORTS_LINK_TITLE: "Reports",
    GALLERY_LINK_TITLE: "Gallery",
    BLOG_LINK_TITLE: "Blog",
    NEWS_LINK_TITLE: "News",

    //Main Page
    //Hero
    HERO_HASHTAG: "#Stronger\nTogether",
    HERO_IMG_PRIVACY: `* «…An exception to the general rule, when it is not necessary to obtain consent, is filming in public places: on the street, at meetings, conferences, rallies and other events of a public nature.
Source: website of the Ministry of Justice "Rules of filming: when permission is required and when not»`,
    PAYMEMT_BTN: "Help",

    //History
    HISTORY_TITLE: "We are Ukrainians!",
    HISTORY_TEXT_1: `And that already means a lot.
Until February 24, each of us was busy with our own lives. Someone was engaged in business,
someone photographed beauty, someone conducted trainings and consultations, and
someone built his career abroad in general.`,
    HISTORY_TEXT_2: `By such a sad coincidence, we all met in Chernivtsi and started
            to help displaced persons whose cities were hit by enemy missiles
            or already completely destroyed. We quickly set up the kitchen and started
            to feed these people, then they started connecting with us
            business partners. And then there was the reception of large batches of children's food
            food, clothes, medical drugs from friends from abroad.
            Having closed the requests of acquaintances and friends, we moved on
            full reception and delivery of humanitarian aid.`,
    HISTORY_TEXT_3: `But it was still not enough...
Many are simply not able to try to build another life after
losses and injuries received. Therefore, we opened on the basis of the fund
Psychological center and started free counseling. Simply
talked with people, simply gave them meaning, hope and faith in the future.
But even that was not enough... because the incredible began to arrive in the city
number of children….`,
    ADDITIONAL_HISTORY_TITLE: "Now",
    ADDITIONAL_HISTORY_TEXT_1: `Now we are working on the adaptation of children in the conditions of war, we provide
          advising families and helping children's shelters with organization
          leisure for children. The humanitarian headquarters is still working, but the main one
          profile of our fund today in`,
    ADDITIONAL_HISTORY_TEXT_1_BOLD: ` social adaptation and psychological counseling of children, especially
            those who lost their parents in the war.`,
    ADDITIONAL_HISTORY_TEXT_2: `We really dream of building a shelter for orphans and creating a Center
          prosthetics, where children with disabilities will be able to get everything they need,
          regarding prosthetics and undergoing rehabilitation.`,
    ADDITIONAL_HISTORY_TEXT_3: `The future of Ukraine is in your and our hands, so we are ready to contribute
          forced, so that the "children of war" could become beautiful, self-confident,
          successful "adults" admired by the whole world`,
    HISTORY_HASHTAG: "#strongertogether🤍",

    //Activity
    ACTIVITY_TITLE: `For 1 year of activity:`,
    BUBLE1_SMALL: "about",
    BUBLE1_AMOUNT: "35",
    BUBLE1_OBJECT: "institutions",
    BUBLE1_ACTION: "received medical support",

    BUBLE2_SMALL: "more than",
    BUBLE2_AMOUNT: "9000",
    BUBLE2_ACTION: "fed families",

    BUBLE3_SMALL: "nearly",
    BUBLE3_AMOUNT: "45",
    BUBLE3_OBJECT: "partners",
    BUBLE3_ACTION: "helped with the search for resources",

    BUBLE4_AMOUNT: "87",
    BUBLE4_OBJECT: "tons of",
    BUBLE4_ACTION: "humanitarian aid received and sent to hot spots and de-occupied cities of Ukraine",

    BUBLE5_SMALL: "nearly",
    BUBLE5_AMOUNT: "95",
    BUBLE5_OBJECT: "thousands of children",
    BUBLE5_ACTION: "received psychological help",

    //Help Page
    BANK_DETAILS_TITLE: "REQUISITES",
    MANUALLY_TITLE: "Enter manually:",
    QR_TITLE: "qr-code:",
    GIVE_SMILE_TITLE: "or GIVE AN INSTANT SMILE TO A BABY:",
    GIVE_SMILE_SUBTITLE: "*plus to karma is guaranteed",
    HOW_HELP_TITLE: "How to help",
    HOW_HELP_TITLE_CONTINUE: "little Ukrainians to become happier?",
    OPTION_BTN: "fill application",
    OPTION1_DESC: "If you want to volunteer and bring happiness to displaced people with your actions ",
    OPTION1_BOLD: "as a volunteer",
    OPTION2_DESC: "If you want to help young Ukrainians cope with anxiety, improve their emotional state ",
    OPTION2_BOLD: "as a psychologist",
    OPTION3_DESC: "If you are unable to help physically, but also want to contribute to the carefree childhood of Ukrainians ",
    OPTION3_BOLD: "as a partner",

    //Projects Page
    PROJECTS_TITLE: "Our projects:",
    PROJECTS_DETAILS_BTN: "More",

    //TeamPage
    TEAM_TITLE: "Our Team",
    PSYCHOLOGISTS_TITLE: "Medical and psychological center",
    PSYCHOLOGISTS_CARD_BTN: "Blog",

    //Partners Page
    PARTNERS_TITLE: "Our partners:",
    GRATITUDE_TITLE: "Gratitude",
    GRATITUDE_TEXT0: "All our achievements and achievements would be impossible without people who constantly responded and helped us in every project.",
    GRATITUDE_TEXT1: "Therefore, we want to SINCERELY and HEARTLY thank our partners and patrons who shared resources, sent funds and helped us bring our ideas to life.",
    GRATITUDE_TEXT2: "Thanks to you, we can work and do even more good for little Ukrainians! Thanks to you, we ourselves continue to believe in this GOOD! Thanks to you, we have no hatred, but only a strong belief in the best! And to Ukraine!",
    GRATITUDE_TEXT3: "Because what the nation of Ukrainians is doing now inspires not only the whole world, but also us.",
    GRATITUDE_THANKS: "THANKS!",
    GRATITUDE_MORE: "Further - more!❤️",

    //Reports Page
    REPORTS_TITLE: "Reports:",
    REPORTS_GALLERY_TITLE: "Gallery",
    REPORTS_YEAR: " Year",

    //Gallery Page
    GALLERY_TITLE: "Gallery",
    GALLERY_MORE_BTN: "More",

    //Blog Page
    BLOG_TITLE: "Blog:",
    BLOG_BTN: "Read more",

    //Contacts Page
    CONTACTS_TITLE: "Contacts:",
    CURRICULUM_TITLE: "SCHEDULE:",
    CURRICULUM_VALUE: "Monday-Friday\nfrom 10:00 to 18:00",
    PHONE_TITLE: "PHONE NUMBER:",
    PHONE_VALUE: "+38 093 630 7777",
    ADDRESS_TITLE: "ADDRESS:",
    ADDRESS_VALUE: "Chernivtsi, Main street, 34",
    EMAIL_TITLE: "EMAIL:",
    EMAIL_VALUE: "INFO@WORLDOFFUTURE.ORG",
    DETAILS_TITLE: "REQUISITES:",
    DETAILS_VALUE: "UA863562820000026002051534284\nБФ СВІТ МАЙБУТНЬОГО БО\nКод отримувача: 42419304",

    //News Title
    NEWS_TITLE: "News",
    NEWS_CARD_BTN: "Read more",
    NEWS_CARD_BTN_CLOSE: "Close",
    MORE_NEWS_BUTTON: "Download more news",

    //Need Help Page
    NEED_HELP_TITLE: "NEED\nHELP?",
    NEED_HELP_TEXT1_BLUE: "If you or your relatives",
    NEED_HELP_TEXT1_FIRST: "were forced to move to another region of the country as a result of Russian aggression and",
    NEED_HELP_TEXT1_BOLD: " you need any humanitarian aid ",
    NEED_HELP_TEXT1_SECOND: "fill out our application form below",
    FILL_BTN: "FILL APPLICATION",

    NEED_HELP_TEXT2_BLUE: "If you or your family",
    NEED_HELP_TEXT2_BOLD: " needs professional support or psychological help ",
    NEED_HELP_TEXT2_FIRST: "contact our psychologists",

  }
}

export default content;