import { configureStore, combineReducers } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import { api } from './api';
import languagesReducer from './slices/languages';
import adminModeReducer from './slices/adminMode';

const rootReducer = combineReducers({
    user: userReducer,
    languages: languagesReducer,
    adminMode: adminModeReducer,
    [api.reducerPath]: api.reducer
})

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(api.middleware)
})

export default store