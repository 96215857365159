import { createReducer } from "@reduxjs/toolkit";

const initialState = {
    adminMode: false,
}

const adminModeReducer = createReducer(initialState, {
    TOGGLE_ADMIN_MODE: (state) => {
        state.adminMode = !state.adminMode;
    },
});

export default adminModeReducer;
