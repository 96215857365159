export const months = {
    eng: [
        "JANUARY",
        "FEBRUARY",
        "MARCH",
        "APRIL",
        "MAY",
        "JUNE",
        "JULY",
        "AUGUST",
        "SEPTEMBER",
        "OCTOBER",
        "NOVEMBER",
        "DECEMBER",
    ],
    ua: [
        "СІЧЕНЬ",
        "ЛЮТИЙ",
        "БЕРЕЗЕНЬ",
        "КВІТЕНЬ",
        "ТРАВЕНЬ",
        "ЧЕРВЕНЬ",
        "ЛИПЕНЬ",
        "СЕРПЕНЬ",
        "ВЕРЕСЕНЬ",
        "ЖОВТЕНЬ",
        "ЛИСТОПАД",
        "ГРУДЕНЬ",
    ]
};

const monthsModified = {
    eng: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    ua: [
        "січня",
        "лютого",
        "березня",
        "квітня",
        "травня",
        "червня",
        "липня",
        "серпня",
        "вересня",
        "жовтня",
        "листопада",
        "грудня",
    ]
}

export const getDateString = (dateObj, lang) => {
    if (!dateObj) return "";
    const dateStr = dateObj.slice(0, 10);
    const dateArr = dateStr.split('-');
    const year = dateArr[0];
    const month = dateArr[1];
    const day = dateArr[2];

    return `${day} ${monthsModified[lang][month - 1]} ${year}`;
}

export const sortByEventDate = (arr) => {
    return arr && arr.sort((a, b) => {
        if (a.galleryDate && b.galleryDate) {
            return new Date(b.galleryDate) - new Date(a.galleryDate)
        } else if (a.galleryDate) {
            return -1;
        } else if (b.galleryDate) {
            return 1;
        } else {
            return 0;
        }
    })
}