import { useEffect, lazy, Suspense, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom"
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Loader from "./components/Loader/Loader";
import { checkUser } from "./redux/slices/userSlice";
import { setLastLanguage } from "./redux/slices/languages";
import NotFound from "./routes/Errors/NotFound";
import ServerError from "./routes/Errors/ServerError";
import About from "./routes/About/About";
import GalleryCategoryEditor from "./routes/Editors/GalleryCategoryEditor";
import GalleryEditor from "./routes/Editors/GalleryEditor";
import TogetherPoints from "./routes/Gallery/GalleryEnteties/TogetherPoints";
import Videos from "./routes/Gallery/GalleryEnteties/Videos";
import Together from "./routes/Project/ProjectEnteties/Together";
import Humanitarian from "./routes/Project/ProjectEnteties/Humanitarian";
import ToyLibrary from "./routes/Project/ProjectEnteties/ToyLibrary";
import Psychological from "./routes/Project/ProjectEnteties/Psychological";
import ProjectLayout from "./routes/Project/ProjectEnteties/ProjectLayout";
import TogetherPoint from "./routes/Gallery/GalleryEnteties/TogetherPoint";

//Editors
const ProjectEditor = lazy(() => import("./routes/Editors/ProjectEditor"))
const ReportEditor = lazy(() => import("./routes/Editors/ReportEditor"))
const MembersEditor = lazy(() => import("./routes/Editors/MembersEditor"))
const BlogsEditor = lazy(() => import("./routes/Editors/BlogEditor"))

//Pages
const BlogItem = lazy(() => import("./routes/BlogItem/BlogItem"))
const Help = lazy(() => import("./routes/Help/Help"))
const Blog = lazy(() => import("./routes/Blog/Blog"))
const Contacts = lazy(() => import("./routes/Contacts/Contacts"))
const News = lazy(() => import("./routes/News/News"))
const Gallery = lazy(() => import("./routes/Gallery/Gallery"))
const Main = lazy(() => import("./routes/Main/Main"))
const Partners = lazy(() => import("./routes/Partners/Partners"))
const Projects = lazy(() => import("./routes/Projects/Projects"))
const Reports = lazy(() => import("./routes/Reports/Reports"))
const Team = lazy(() => import("./routes/Team/Team"))
const NeedHelp = lazy(() => import('./routes/NeedHelp/NeedHelp'))
const Report = lazy(() => import("./routes/Report/Report"))
const Project = lazy(() => import("./routes/Project/Project"))
const TeamMember = lazy(() => import("./routes/TeamMember/TeamMember"))
const Login = lazy(() => import("./routes/Login/Login"))
const Admin = lazy(() => import("./routes/Admin/Admin"))
const GalleryCategory = lazy(() => import('./routes/GalleryCategory/GalleryCategory'))

const RouteChangeWrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
};

const LoadingWatcher = () => {
  const isSomeQueryLoading = useSelector(state => Object.values(state.api.queries).some(query => query.status === "pending"));
  useEffect(() => {
    if (isSomeQueryLoading) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    }
  }, [isSomeQueryLoading])

  return isSomeQueryLoading && <Loader />;
};


function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setLastLanguage())
    dispatch(checkUser())
  }, [])

  return (
    <RouteChangeWrapper>
      <Header />
      <div className="content">
        <Suspense fallback={<Loader />}>

          <Routes>
            <Route index path="/projects" element={<Projects />} />
            <Route path="/projects" element={<ProjectLayout />}>
              <Route path="/projects/together" element={<Together />} />
              <Route path="/projects/humanitarianHelp" element={<Humanitarian />} />
              <Route path="/projects/toyLibrary" element={<ToyLibrary />} />
              <Route path="/projects/psychologicalWork" element={<Psychological />} />
              <Route path="/projects/:projectId" element={<Project />} />
            </Route>
            <Route path="/about" element={<About />} />
            <Route path="/help" element={<Help />} />
            <Route index path="/team" element={<Team />} />
            <Route path="/team/:userId" element={<TeamMember />} />
            <Route path="/partners" element={<Partners />} />
            <Route index path="/reports" element={<Reports />} />
            <Route path="/reports/:reportId" element={<Report />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/gallery/videos" element={<Videos />} />
            <Route path="/gallery/togetherPoints" element={<TogetherPoints />} />
            <Route path="/gallery/togetherPoints/:togetherPointName" element={<TogetherPoint />} />
            <Route path="/gallery/:galleryId" element={<GalleryCategory />} />
            <Route path="/news" element={<News />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:blogId" element={<BlogItem />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/need-help" element={<NeedHelp />} />
            <Route path="/auth" element={<Login />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/editor">
              <Route path="gallery">
                <Route index element={<GalleryEditor />} />
                <Route path=":galleryId" element={<GalleryEditor />} />
              </Route>
              <Route path="galleryCategory">
                <Route index element={<GalleryCategoryEditor />} />
                <Route path=":galleryCategoryId" element={<GalleryCategoryEditor />} />
              </Route>
              <Route path="blog" >
                <Route index element={<BlogsEditor />} />
                <Route path=":blogId" element={<BlogsEditor />} />
              </Route>
              <Route path="projects">
                <Route index element={<ProjectEditor />} />
                <Route path=":projectId" element={<ProjectEditor />} />
              </Route>
              <Route path="reports" >
                <Route index element={<ReportEditor />} />
                <Route path=":reportId" element={<ReportEditor />} />
              </Route>
              <Route path="members" >
                <Route index element={<MembersEditor />} />
                <Route path=":memberId" element={<MembersEditor />} />
              </Route>
            </Route>
            <Route path="/error" element={<ServerError />} />
            <Route path="/" index element={<Main />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </div>
      <Footer />
      <LoadingWatcher />
      {/* <ErrorWatcher /> */}
    </RouteChangeWrapper>
  );
}

export default App;
