import pointsImages from "./pointsImages.json";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Virtuoso } from "react-virtuoso";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import styles from "./TogetherPoint.module.scss";
import { cities } from "../TogetherPoints";

const TogetherPoint = () => {
  const { currentLanguage } = useSelector((state) => state.languages);
  const { togetherPointName } = useParams();

  const images = pointsImages?.find(
    (point) => point.city === togetherPointName
  )?.images;

  const pointName =
    cities.find((point) => point.redirectTo === togetherPointName)?.[
      currentLanguage
    ] || togetherPointName;

  return (
    <div className={styles.togetherPoint}>
      <h1 className={styles.togetherPoint__title}>
        {currentLanguage === "ua" ? "Галерея точки у " : "Gallery of "}
        {pointName}
      </h1>
      <Virtuoso
        useWindowScroll
        style={{ width: "100%", height: "70vh" }}
        totalCount={images?.length}
        data={images}
        itemContent={(index) => (
          <div className={styles.togetherPoint__imageWrapper} key={index}>
            <LazyLoadImage
              src={images[index]}
              alt={togetherPointName}
              effect="blur"
              className={styles.togetherPoint__image}
            />
          </div>
        )}
      />
    </div>
  );
};

export default TogetherPoint;
