import { NavLink } from "react-router-dom";
import NavItemIcon from "../../../assets/NavItemIcon.png";

const NavigationItem = ({ toggleMenu, text, url }) => (
  <li onClick={toggleMenu} className="navigation-item">
    <NavLink to={url}>
      {text}
      <img src={NavItemIcon} alt="nav-icon" className="nav-icon" />
    </NavLink>
  </li>
);

export default NavigationItem