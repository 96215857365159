import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/Logo.png";
import content from "../../content/content";
import { setLanguage } from "../../redux/slices/languages";
import AdminIcon from "../../assets/icons/adminIcon.png";
import "./Header.scss";
import Navigation from "./Navigation/Navigation";

const Header = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { currentLanguage } = useSelector((state) => state.languages);
  const { adminMode } = useSelector((state) => state.adminMode);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const detectClick = (e) => {
    if (
      !document.querySelector(".navigation").contains(e.target) &&
      isMenuOpen
    ) {
      toggleMenu();
    }
  };

  const handleLanguageChange = () => {
    if (currentLanguage === "eng") {
      dispatch(setLanguage("ua"));
    } else {
      dispatch(setLanguage("eng"));
    }
  };

  const handleToggleAdmin = () => {
    dispatch({ type: "TOGGLE_ADMIN_MODE" });
  };

  return (
    <>
      <header>
        <NavLink className="logo" to="/">
          <img src={Logo} alt="logo" className="logo" />
        </NavLink>

        <ul className="header-list">
          {user?.userType === "admin" && (
            <li className="header-item admin-item">
              <Link to={"admin"}>
                <img src={AdminIcon} alt="admin" className="admin-icon" />
              </Link>
              <button
                onClick={handleToggleAdmin}
                className={`toggle-btn ${adminMode ? "active" : ""}`}
              >
                Admin Mode
              </button>
            </li>
          )}

          <li className="header-item how-help-link">
            <NavLink to={"/help"}>
              {content[currentLanguage].HOW_TO_HELP_LINK_TITLE}
            </NavLink>
          </li>
          <li className="header-item need-help">
            <Link to={"need-help"}>
              {content[currentLanguage].NEED_HELP_LINK_TITLE}
            </Link>
          </li>
          <li className="header-item language">
            <button className="language-btn" onClick={handleLanguageChange}>
              {currentLanguage === "ua" ? "eng" : "ua"}
            </button>
          </li>
          <li className="header-item">
            <button className="burger-btn" onClick={toggleMenu}>
              <span className="burger-line"></span>
            </button>
          </li>
        </ul>
      </header>

      <Navigation
        currentLanguage={currentLanguage}
        detectClick={detectClick}
        toggleMenu={toggleMenu}
        isMenuOpen={isMenuOpen}
      />
    </>
  );
};

export default Header;
