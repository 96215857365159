import { useSelector } from "react-redux";
import InfiniteSlideshow from "../../../../components/InfiniteSlideshow/InfiniteSlideshow";
import styles from "../Entry.module.scss";
import { images } from "./constants";
import { Link } from "react-router-dom";

const Together = () => {
  const { currentLanguage } = useSelector((state) => state.languages);

  return (
    <section className={styles.project}>
      <h4 className={`title ${styles.projectTitle}`}>
        {currentLanguage === "eng"
          ? "MHPPS on points TOGETHER"
          : " MHPPS на точках СПІЛЬНО"}
      </h4>
      <div className={styles.slideshow}>
        <InfiniteSlideshow images={images.map((img) => img)} />
      </div>
      <div className={styles.projectDesc}>
        {currentLanguage === "eng" ? (
          <>
            <p>
              MHPSS is the direction of our foundation's work, which involves
              providing psychosocial assistance to children and their families
              who have experienced military aggression, as well as improving the
              quality of mental health and psychosocial well-being.
            </p>
            <p>
              We implement MHPSS at children's points TOGETHER by conducting
              psychological activities in a group format (training for children
              and adults, art therapy classes) and individual consultations.
            </p>
            <h6 className={styles.headingBlue}>We understand,</h6>
            <p>
              that the psychological well-being of children is primarily
              determined by their family environment. The family is a space and
              at the same time a factor in the child's development, as it unites
              elements that contribute to the development of the functional
              state of the child's body, the activation of his psychological
              resources, resistance to negative influences, the achievement and
              maintenance of emotional comfort, and also lay the basis for
              self-development.{" "}
            </p>
            <p className={styles.blue}>
              That is why we consider it necessary to work together with the
              family in order to preserve the psychological well-being and the
              right to a happy childhood of every young Ukrainian man and woman.
            </p>
          </>
        ) : (
          <>
            <p>
              MHPSS це напрямок роботи нашого фонду, який передбачає надання
              психосоціальної допомоги дітям та їхнім сім'ям, які зазнали
              військової агресії, а також покращення якості психічного здоров'я
              та психосоціального благополуччя.
            </p>
            <p>
              Ми реалізовуємо MHPSS на дитячих точках СПІЛЬНО шляхом проведення
              психологічних заходів групового формату (тренінги для дітей та
              дорослих, арт-терапевтичні заняття) та індивідуальні консультації.
            </p>
            <h6 className={styles.headingBlue}>Ми розуміємо,</h6>
            <p>
              що психологічне благополуччя дітей визначається передусім її
              сімейним середовищем. Сім’я є простором і водночас фактором
              розвитку дитини, оскільки об’єднує елементи, які сприяють розвитку
              функціонального стану організму дитини, активізації її
              психологічних ресурсів, опірності до негативних впливів,
              досягненню і підтриманню емоційного комфорту, а також закладають
              базис саморозвитку.{" "}
            </p>
            <p className={styles.blue}>
              Саме тому, ми вважаємо необхідністю працювати загалом з сімʼєю
              задля збереження психологічного благополуччя та права на щасливе
              дитинство кожного маленького українця та україночки.
            </p>
          </>
        )}
      </div>


      <Link to="/gallery/togetherPoints" className={styles.galleryLink}>
        {currentLanguage === "eng" ? "Go to gallery" : "Перейти до галереї"}
      </Link>

      <div className={styles.directions}>
        <h3 className="title">
          {currentLanguage === "eng"
            ? "Areas of activity:"
            : "Напрямки діяльності:"}
        </h3>
        <div className={styles.directionsList}>
          <Link
            to="/projects/psychologicalWork"
            className={styles.directionsItem}
          >
            {currentLanguage === "eng"
              ? "Psychological measures"
              : "Психологічні заходи"}
          </Link>
          <Link
            to="/projects/humanitarianHelp"
            className={styles.directionsItem}
          >
            {currentLanguage === "eng"
              ? "Humanitarian help"
              : "Гуманітарна допомога"}
          </Link>
          <Link to="/projects/toyLibrary" className={styles.directionsItem}>
            {currentLanguage === "eng" ? "Toy library" : "Бібліотка іграшок"}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Together;
