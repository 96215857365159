import axios from "axios";
import { useEffect } from "react";
import "./ImageUploader.scss";

const ImageUploader = ({ img, setImage }) => {
  useEffect(() => {}, [img?.url]);

  const uploadImage = async (e) => {
    if (!e.target.files[0]) return;
    try {
      if (img?.public_id)
        await axios.delete(
          `"https://world-of-future-api.onrender.com/api/images`,
          { data: img }
        );
    } catch (err) {
      console.log(err);
    }

    const files = e.target.files;
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("upload_preset", "gc9giuxz");
    await axios
      .post("https://api.cloudinary.com/v1_1/dlmpvt709/image/upload", formData)
      .then((res) => {
        setImage({
          url: res.data.url,
          public_id: res.data.public_id,
        });
      });
  };

  return (
    <div className="uploader-settings">
      <img src={img?.url} alt="Image" className="uploader-img" />

      <input
        type="file"
        style={{ display: "none" }}
        onChange={uploadImage}
        id="file"
      />
      <label className="uploader-btn" htmlFor="file">
        {img?.url ? "Change Image" : "Upload Image"}
      </label>
    </div>
  );
};

export default ImageUploader;
