import React from 'react'
import InfiniteSlideshow from '../../../../components/InfiniteSlideshow/InfiniteSlideshow'
import styles from '../Entry.module.scss'
import { images } from './constants'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Humanitarian = () => {
  const { currentLanguage } = useSelector((state) => state.languages);

  return (
    <section className={styles.project}>
      <h4 className={`title ${styles.projectTitle}`}>
        {currentLanguage === "eng" ? "Humanitarian help" : "Гуманітарна допомога"}
      </h4>
      <div className={styles.slideshow}>
        <InfiniteSlideshow images={images.map((img) => img)} />
      </div>
      <div className={styles.projectDesc}>
        {currentLanguage === "eng" ? (<>
          <p>The prosthetics center is our future project, which will help young Ukrainians with special needs to feel like full members of society. We will build the Center and try to find the necessary resources for its construction and operation, involving the leading doctors of Europe. </p>
        </>) : (
        <>
          <p>Центр протезування - наш майбутній проект, який допоможе маленьким українцям з особливостями відчувати себе повноцінними членами суспільства. Ми збудуємо Центр і спробуємо знайти необхідні ресурси на будівництво та його функціонування, залучаючи провідних лікарів Європи. </p>
        </>
        )}

      </div>

      <div className={styles.directions}>
                <h3 className='title'>{currentLanguage === "eng" ? "Areas of activity:" : "Напрямки діяльності:"}</h3>
                <div className={styles.directionsList}>
                    <Link to='/projects/psychologicalWork' className={styles.directionsItem}>
                        {currentLanguage === "eng" ? "Psychological measures" : "Психологічні заходи"}
                    </Link>
                    <Link to='/projects/toyLibrary' className={styles.directionsItem}>
                        {currentLanguage === "eng" ? "Toy library" : "Бібліотка іграшок"}
                    </Link>
                </div>
            </div>
    </section>
  )
}

export default Humanitarian