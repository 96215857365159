import { useParams } from "react-router-dom";
import ImageUploader from "../../components/ImageUploader/ImageUploader";
import { useEditor } from "../../hooks/useEditor";
import {
  useAddGalleryCategoryMutation,
  useEditGalleryCategoryMutation,
  useGetGalleryCategoryQuery,
} from "../../redux/api/galleryCategoryApi";
import "./Editors.scss";

const GalleryCategoryEditor = () => {
  const { galleryCategoryId } = useParams();
  const isAddMode = !galleryCategoryId;
  const { data } = useGetGalleryCategoryQuery(galleryCategoryId);

  const {
    changeLanguage,
    language,
    getFieldValue,
    getTranslationField,
    setFieldValue,
    setTranslationField,
    state,
  } = useEditor(data, "galleryCategory");

  const [addGalleryCategory] = useAddGalleryCategoryMutation();
  const [editGalleryCategory] = useEditGalleryCategoryMutation();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isAddMode) {
      addGalleryCategory(state);
    } else {
      editGalleryCategory({ galleryCategoryId, blogData: state });
    }
  };

  const setImage = (img) => {
    setFieldValue("img", img);
  };

  return (
    <div className="editor-wrapper">
      <h1 className="editor-title">
        {isAddMode ? "Add Blog" : `Edit Blog ${galleryCategoryId}`}
      </h1>
      <form onSubmit={handleSubmit} className="editor-form member-form">
        <div className="form-image">
          <ImageUploader setImage={setImage} img={getFieldValue("img")} />
        </div>
        <div className="form-content">
          <div className="form-group language">
            <label htmlFor="name">Language</label>
            <select value={language} onChange={changeLanguage}>
              <option value="ua">Українська</option>
              <option value="eng">English</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              value={getTranslationField("name")}
              onChange={(e) => setTranslationField("name", e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="surname">Gallery Description</label>
            <textarea
              name="galleryDesc"
              id="galleryDesc"
              value={getTranslationField("galleryDesc")}
              onChange={(e) =>
                setTranslationField("galleryDesc", e.target.value)
              }
            />
          </div>

          <button className="form-submit submit-btn" type="submit">
            {isAddMode ? "Add" : "Edit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default GalleryCategoryEditor;
