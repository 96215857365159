import { useSelector } from "react-redux";
import ProjectsComponent from "../../components/ProjectsComponent";
import content from "../../content/content";
import errorLeft from "../../assets/errors/errorLeft.png";
import errorRight from "../../assets/errors/errorRight.png";
import { Link } from "react-router-dom";
import "./Errors.scss";

const NotFound = () => {
  const { currentLanguage } = useSelector((state) => state.languages);
  return (
    <div className="error-page">
      <section className="error">
        <img src={errorLeft} className="error-left-pattern" />
        <img src={errorRight} className="error-right-pattern" />
        <h2 className="error-title title">
          {content[currentLanguage].NOT_FOUND_TITLE}
        </h2>
        <p className="error-text">{content[currentLanguage].NOT_FOUND_TEXT}</p>
        <p className="error-text">
          {content[currentLanguage].NOT_FOUND_TEXT2}
          <br />
          <span className="error-text__bold">
            {content[currentLanguage].NOT_FOUND_TEXT2_BOLD}
          </span>
        </p>

        <a className="btn" href="https://pay.fondy.eu/s/ClTDdXb9aIYfY4">
          {content[currentLanguage].PAYMEMT_BTN}
        </a>
      </section>
      <ProjectsComponent />
      <Link className="btn" to="/">
        {content[currentLanguage].ERROR_LINK}
      </Link>
    </div>
  );
};

export default NotFound;
