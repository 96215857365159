import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: "https://world-of-future-api.onrender.com/api",
    // baseUrl: "http://localhost:8800/api",
    prepareHeaders: (headers, { getState }) => {
        const user = getState().user.user
        if (user) {
            headers.set('Authorization', `Bearer ${user.token}`)
            headers.set('Content-Type', 'application/json')
        }
        return headers
    },
    credentials: 'include'
})

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQuery,
    tagTypes: ["News", "Gallery", "Projects", "Reports", "Users"],
    endpoints: builder => ({})
})


