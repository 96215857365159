import React from 'react'
import InfiniteSlideshow from '../../../../components/InfiniteSlideshow/InfiniteSlideshow'
import styles from '../Entry.module.scss'
import { images } from './constants'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ToyLibrary = () => {
    const { currentLanguage } = useSelector((state) => state.languages);

    return (
        <section className={styles.project}>
            <h4 className={`title ${styles.projectTitle}`}>
                {currentLanguage === 'eng' ? 'Toy library' : ' Бібліотека іграшок'}

            </h4>
            <div className={styles.slideshow}>
                <InfiniteSlideshow images={images.map((img) => img)} />
            </div>
            <div className={styles.projectDesc}>
                {currentLanguage === 'eng' ? (
                    <>
                        <p>With the support of UNICEF Ukraine, our charitable foundation "The World of the Future" implements the Joint Toys project. This is a program that provides children with access to toys for early development through a network of children's points TOGETHER. The mission is to promote the realization of children's right to education.</p>
                        <p>That is, each visitor of the children's space TOGETHER can use any toy, and after returning it can choose another. </p>
                        <p>In general, this is the so-called play therapy, which is a leader in working with children in terms of learning about the world, expanding their horizons, learning to communicate with others.</p>
                        <p> We focus special attention in the space of children's points TOGETHER on fairy-tale therapy for children, which is an effective way of integrating the personality, developing creative and mental abilities. It is fairy-tale therapy that is a safe tool for psychocorrective work with children from the youngest age, with the help of which you can understand what exactly worries the baby, what caused certain reactions and how to help recover</p>
                        <p> We sincerely invite you and your children to visit psychological events at children's points TOGETHER, and also to choose from the TOY LIBRARY exactly the toy that will not only be a child's anti-stress, but also help in the development of the necessary skills.</p>
                    </>
                ) : (
                    <>
                        <p>За підтримки ЮНІСЕФ Україна, наш благодійний фонд «Світ майбутнього» реалізує проєкт Спільно Іграшки. Це програма, що забезпечує доступ дітей до іграшок для раннього розвитку через мережу дитячих точок СПІЛЬНО. Місія - сприяти реалізації права дітей на освіту.</p>
                        <p>Тобто, кожен відвідувач дитячого простору СПІЛЬНО може взяти в користування будь-яку іграшку, а повернувши її може обрати іншу. </p>
                        <p>Загалом, це так звана, ігрова терапія, яка є провідною у роботі з дітьми в плані пізнання світу, розширення кругозору, навчання комунікації з іншими.</p>
                        <p> Ми акцентуємо особливу увагу в просторі дитячих точок СПІЛЬНО на казкотерапії для дітей, яка є ефективним способом інтеграції особистості, розвитку творчих та розумових здібностей. Саме казкотерапія є безпечним інструментом психокорекційної роботи з дітками з наймолодшого віку, за допомогою якого можна зрозуміти, що саме хвилює малюка, що стало причиною тих чи інших реакцій і як допомогти відновитися</p>
                        <p> Ми щиро запрошуємо Вас та Ваших дітей відвідати психологічні заходи на дитячих точках СПІЛЬНО, а також обрати з БІБЛІОТЕКИ ІГРАШОК саме ту іграшку, яка буде не тільки дитячим антистресом, а й допоможе в розвитку потрібних навичок.</p>
                    </>
                )}
            </div>

            <div className={styles.directions}>
                <h3 className='title'>{currentLanguage === "eng" ? "Areas of activity:" : "Напрямки діяльності:"}</h3>
                <div className={styles.directionsList}>
                    <Link to='/projects/psychologicalWork' className={styles.directionsItem}>
                        {currentLanguage === "eng" ? "Psychological measures" : "Психологічні заходи"}
                    </Link>
                    <Link to='/projects/humanitarianHelp' className={styles.directionsItem}>
                        {currentLanguage === "eng" ? "Humanitarian help" : "Гуманітарна допомога"}
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default ToyLibrary