import React from 'react'
import InfiniteSlideshow from '../../../../components/InfiniteSlideshow/InfiniteSlideshow'
import styles from '../Entry.module.scss'
import { images } from './constants'
import { Link } from 'react-router-dom'

const Psychological = () => {
  const groupWork = {
    eng: [],
    ua: ["тренінги", "арт-терапевтичні заходи", "роботу з метафоричними асоціативними картами (МАК)", "ігрова терпія / казкотерапія", "фільмо- та мультикотерапія"]
  }
    return (
        <section className={styles.project}>
            <h4 className={`title ${styles.projectTitle}`}>
                Психологічні заходи
            </h4>
            <div className={styles.slideshow}>
                <InfiniteSlideshow images={images.map((img) => img)} />
            </div>
            <div className={styles.projectDesc}>
                <p>Заходи з надання психологічної допомоги дітям та їх сімʼям здійснюють фахівці-психологи з вищою психологічною освітою, курсами спеціальної підготовки та досвідом групової й індивідуальної роботи. Індивідуальні консультації можуть бути очними (в офісі) та в режимі онлайн (відео- чи аудіозв’язок). </p>
               
               <h6 className={styles.listHeading}>Групова робота включає: </h6>
               <ul>
                    {groupWork.ua.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
               </ul>
                <p>Заходи від БФ «Світ майбутнього» спрямовані на формування емоційного інтелекту в дітей, розвиток навичок групової взаємодії та екологічне проживання внутрішніх конфліктів, тривоги й  страхів.  Для дорослих - це чудова можливість навчитися простим та дієвим технікам відновлення внутрішніх сил та життєвого балансу, вміти краще контролювати свої емоції та повернути собі бажання до якісного проживання кожної життєвої миті. Саме тут можна отримати позитивні враження, гарний настрій та заряд енергії. </p>
               </div>

            <div className={styles.directions}>
                <h3 className='title'>Напрямки діяльності:</h3>
                <div className={styles.directionsList}>
                    <Link to='/projects/humanitarianHelp' className={styles.directionsItem}>
                        Гуманітарна допомога
                    </Link>
                    <Link to='/projects/toyLibrary' className={styles.directionsItem}>
                        Бібліотка іграшок
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default Psychological