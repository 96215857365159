import { useState } from "react"

export const useFormLanguage = () => {
    const [language, setLanguage] = useState("ua")

    const changeLanguage = (e) => {
        setLanguage(e.target.value)
    }

    return { language, changeLanguage }
}