import { useRef, useEffect, useState } from "react";
import "./InfiniteSlideshow.scss";

const InfiniteSlideshow = ({ images, dir = 1 }) => {
  const [visibleImages, setVisibleImages] = useState(3);
  const wrapperRef = useRef(null);
  const [xArray, setXArray] = useState(Array(images.length).fill(0));
  const rightOffset = 10;

  const updateXByDirection = (x) => {
    const wrapperWidth = wrapperRef.current.clientWidth;
    const imageWidth = wrapperWidth / visibleImages;

    if (dir === 1) {
      return x + dir >=
        wrapperWidth + imageWidth * (images.length - 1 - visibleImages)
        ? -imageWidth
        : x + dir;
    } else {
      return x + dir <= -imageWidth
        ? wrapperWidth + imageWidth * (images.length - 1 - visibleImages)
        : x + dir;
    }
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setVisibleImages(2);
    } else if (window.innerWidth < 500) {
      setVisibleImages(1);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    const wrapperWidth = wrapperRef.current.clientWidth;
    const imageWidth = wrapperWidth / visibleImages;
    setXArray((prev) => prev.map((_, i) => i * imageWidth));

    const interval = setInterval(() => {
      setXArray((prev) => prev.map(updateXByDirection));
    }, 20);

    return () => clearInterval(interval);
  }, [window.innerWidth, visibleImages]);

  return (
    <div
      className="slideshow-container"
      ref={wrapperRef}
      style={{
        position: "relative",
      }}
    >
      {images.map((image, index) => (
        <div
          key={index}
          style={{
            padding: `0 ${rightOffset}px`,
            position: "absolute",
            left: `${xArray[index]}px`,
            width: `calc(100% / ${visibleImages})`,
          }}
        >
          <img src={image} alt={`Image ${index}`} />
        </div>
      ))}
    </div>
  );
};

export default InfiniteSlideshow;
