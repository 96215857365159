import { useEffect, useReducer } from "react"
import { useFormLanguage } from "./useFormLanguage"

const memberInitialState = {
    img: {
        url: "",
        public_id: "",
    },
    isOrganizer: false,
    isPsychologist: false,
    translations: {
        ua: {
            name: "",
            surname: "",
            role: "",
            about: "",
        },
        eng: {
            name: "",
            surname: "",
            role: "",
            about: "",
        },
    },
}

const blogInitailState = {
    img: {
        url: "",
        public_id: "",
    },
    translations: {
        ua: {
            title: "",
            content: "",
            shortDescription: "",
        },
        eng: {
            title: "",
            content: "",
            shortDescription: "",
        },
    },
};

const galleryCategoryInitialState = {
    img: {
        url: "",
        public_id: "",
    },
    translations: {
        ua: {
            name: "",
            galleryDesc: ""
        },
        eng: {
            name: "",
            galleryDesc: ""
        },
    },
};

const projectInitialState = {
    img: {
        url: "",
        public_id: "",
    },
    gallery: [],
    translations: {
        ua: {
            name: "",
            miniDesc: "",
            desc: "",
        },
        eng: {
            name: "",
            miniDesc: "",
            desc: "",
        },
    },
};

const reportInitialState = {
    month: 0,
    year: new Date().getFullYear(),
    gallery: [],
    translations: {
        ua: {
            desc: "",
        },
        eng: {
            desc: "",
        },
    },
};

const galleryInitialState = {
    galleryCategoryId: "",
    images: [],
    isImportant: false,
    galleryDate: "",
    translations: {
        ua: {
            title: "",
            itemDesc: "",
        },
        eng: {
            title: "",
            itemDesc: "",
        },
    },
};

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_DATA":
            return action.payload
        case "SET_FIELD_VALUE":
            return {
                ...state,
                [action.payload.field]: action.payload.value
            }
        case "SET_TRANSLATION_FIELD":
            return {
                ...state,
                translations: {
                    ...state.translations,
                    [action.payload.language]: {
                        ...state.translations[action.payload.language],
                        [action.payload.field]: action.payload.value
                    }
                }
            }
        default:
            return state
    }
}

const initialStates = {
    "member": memberInitialState,
    "blog": blogInitailState,
    "galleryCategory": galleryCategoryInitialState,
    "project": projectInitialState,
    "report": reportInitialState,
    "gallery": galleryInitialState
}

const getInitialState = (category) => initialStates[category]

// data is the data from the API(members, projects, reports, blogs....)
// Needs editing category to get initial state
export const useEditor = (data, category) => {
    const [state, dispatch] = useReducer(reducer, getInitialState(category))
    const { language, changeLanguage } = useFormLanguage()

    useEffect(() => {
        if (data?._id) {
            dispatch({
                type: "SET_DATA",
                payload: data
            })
        }
    }, [data?._id])

    const getFieldValue = (field) => state[field]
    const getTranslationField = (field) => state.translations[language][field]
    const setFieldValue = (field, value) => {
        dispatch({
            type: "SET_FIELD_VALUE",
            payload: {
                field,
                value
            }
        })
    }
    const setTranslationField = (field, value) => {
        dispatch({
            type: "SET_TRANSLATION_FIELD",
            payload: {
                field,
                value,
                language
            }
        })
    }

    return {
        state,
        dispatch,
        language,
        changeLanguage,
        getFieldValue,
        getTranslationField,
        setFieldValue,
        setTranslationField
    }
}

