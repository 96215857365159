import axios from "axios";
import { useEffect } from "react";
import "./ImageUploader.scss";

const MultipleImagesUploader = ({ images, addImage, removeImage }) => {
  useEffect(() => {}, [images.length]);

  const uploadImage = async (e) => {
    if (!e.target.files[0]) return;

    const files = e.target.files;
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("upload_preset", "gc9giuxz");
    await axios
      .post("https://api.cloudinary.com/v1_1/dlmpvt709/image/upload", formData)
      .then((res) => {
        addImage({
          url: res.data.url,
          public_id: res.data.public_id,
        });
      });
  };

  return (
    <div className="uploader-settings">
      <div className="uploader-images">
        {images.map((img) => (
          <div className="uploader-img-wrapper">
            <img src={img.url} alt="Image" className="uploader-img" />
            <button type="button" onClick={() => removeImage(img)}>
              X
            </button>
          </div>
        ))}
      </div>

      <input
        type="file"
        style={{ display: "none" }}
        onChange={uploadImage}
        id="multipleFile"
      />
      <label className="uploader-btn" htmlFor="multipleFile">
        Add Images
      </label>
    </div>
  );
};

export default MultipleImagesUploader;
