import { useNavigate, useParams } from "react-router-dom";
import { useEditor } from "../../hooks/useEditor";
import {
  useAddGalleryMutation,
  useDeleteGalleryMutation,
  useEditGalleryMutation,
} from "../../redux/api/galleryApi";
import MultipleImagesUploader from "../../components/ImageUploader/MultipleImagesUploader";
import axios from "axios";
import { useGetGalleryCategoriesQuery } from "../../redux/api/galleryCategoryApi";
import { useGetGalleryQuery } from "../../redux/api/galleryApi";
import { useEffect } from "react";

const GalleryEditor = () => {
  const navigate = useNavigate();
  const { galleryId } = useParams();
  const isAddMode = !galleryId;
  const { data: galleryCategories } = useGetGalleryCategoriesQuery();
  const { data } = useGetGalleryQuery(galleryId);

  const {
    changeLanguage,
    language,
    getFieldValue,
    getTranslationField,
    setFieldValue,
    setTranslationField,
    state,
  } = useEditor(data, "gallery");

  const [addGallery, { isSuccess: addedSuccessfully }] =
    useAddGalleryMutation();
  const [editGallery, { isSuccess: editedSuccessfully }] =
    useEditGalleryMutation();
  const [deleteGallery, { isSuccess: deletedSuccessfully }] =
    useDeleteGalleryMutation();

  useEffect(() => {
    if (galleryCategories) {
      setFieldValue("galleryCategoryId", galleryCategories[0]._id);
    }
  }, [galleryCategories]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isAddMode) {
      addGallery(state);
    } else {
      editGallery({ galleryId, galleryData: state });
    }
  };

  const addImage = (img) => {
    setFieldValue("images", [...getFieldValue("images"), img]);
  };

  const removeImage = async (img) => {
    await axios.delete(`"https://world-of-future-api.onrender.com/api/images`, {
      data: img,
    });
    const newImages = getFieldValue("images").filter(
      (image) => image.public_id !== img.public_id
    );
    setFieldValue("images", newImages);
  };

  const handleDelete = () => {
    deleteGallery(galleryId);
  };

  if (addedSuccessfully || editedSuccessfully || deletedSuccessfully) {
    navigate('/admin')
  }

  return (
    <div className="editor-wrapper">
      <h1 className="editor-title">
        {isAddMode ? "Add Gallery" : `Edit Gallery ${galleryId}`}
      </h1>
      <form onSubmit={handleSubmit} className="editor-form member-form">
        <div className="form-image">
          <MultipleImagesUploader
            addImage={addImage}
            removeImage={removeImage}
            images={getFieldValue("images")}
          />
        </div>
        <div className="form-content">
          <div className="form-group language">
            <label htmlFor="name">Language</label>
            <select value={language} onChange={changeLanguage}>
              <option value="ua">Українська</option>
              <option value="eng">English</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="galleryCategoryId">Gallery Category</label>
            <select
              id="galleryCategoryId"
              name="galleryCategoryId"
              value={getFieldValue("galleryCategoryId")}
              onChange={(e) =>
                setFieldValue("galleryCategoryId", e.target.value)
              }
            >
              {galleryCategories &&
                galleryCategories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category?.translations["ua"]?.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              name="title"
              id="title"
              value={getTranslationField("title")}
              onChange={(e) => setTranslationField("title", e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="galleryDate">Gallery Date</label>
            <input
              type="text"
              name="galleryDate"
              id="galleryDate"
              value={getFieldValue("galleryDate")}
              onChange={(e) => setFieldValue("galleryDate", e.target.value)}
              placeholder="2023-01-01"
            />
          </div>
          <div className="checkbox">
            <label htmlFor="isImportant">Important(Show on News)</label>
            <input
              type="checkbox"
              name="isImportant"
              id="isImportant"
              checked={getFieldValue("isImportant")}
              onChange={(e) => setFieldValue("isImportant", e.target.checked)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="itemDesc">Description</label>
            <textarea
              name="itemDesc"
              id="itemDesc"
              value={getTranslationField("itemDesc")}
              onChange={(e) => setTranslationField("itemDesc", e.target.value)}
            />
          </div>

          <button className="form-submit submit-btn" type="submit">
            {isAddMode ? "Add" : "Edit"}
          </button>
          {!isAddMode && (
            <button className="form-submit submit-btn" onClick={handleDelete}>
              Delete
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default GalleryEditor;
