import { sortByEventDate } from "../../utils/dateUtils";
import { api } from "../api";

const galleryApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getGalleries: builder.query({
            query: () => `/gallery`,
            transformResponse: (response) => sortByEventDate(response),
            providesTags: ["Gallery"]
        }),
        getGallery: builder.query({
            query: galleryId => `/gallery/${galleryId}`
        }),
        addGallery: builder.mutation({
            query: galleryData => ({
                url: "/gallery",
                method: 'POST',
                body: galleryData
            }),
            invalidatesTags: ["Gallery"]
        }),
        deleteGallery: builder.mutation({
            query: galleryId => ({
                url: `/gallery/${galleryId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Gallery"]
        }),
        editGallery: builder.mutation({
            query: ({ galleryId, galleryData }) => ({
                url: `/gallery/${galleryId}`,
                method: "PUT",
                body: galleryData
            }),
            invalidatesTags: ["Gallery"]
        }),
        getGalleriesInCategory: builder.query({
            query: categoryId => `/gallery/category/${categoryId}`,
            transformResponse: (response) => sortByEventDate(response),
            providesTags: ["Gallery"]
        }),
        getNews: builder.query({
            query: () => `/gallery/news`,
            transformResponse: (response) => sortByEventDate(response),
            providesTags: ["Gallery"]
        })
    })
})

export const {
    useAddGalleryMutation,
    useDeleteGalleryMutation,
    useEditGalleryMutation,
    useGetGalleryQuery,
    useGetGalleriesQuery,
    useGetGalleriesInCategoryQuery,
    useGetNewsQuery
} = galleryApi
