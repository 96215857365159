import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";

export const login = createAsyncThunk('user/login', async (userCredentials) => {
    const user = await axios.post("https://world-of-future-api.onrender.com/api/auth/login", userCredentials, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })
    // const user = await axios.post("http://localhost:8800/api/auth/login", userCredentials, { withCredentials: true, headers: { 'Content-Type': 'application/json' } })

    return user.data
})

const userSlice = createSlice({
    name: "user",
    initialState: {
        user: null
    },
    reducers: {
        checkUser: (state) => {
            const user = JSON.parse(localStorage.getItem("user"))
            if (user) {
                state.user = user
            }
        },
        logout: (state) => {
            state.user = null
            localStorage.removeItem("user")
        }
    },
    extraReducers: builder => {
        builder.addCase(login.fulfilled, (state, { payload }) => {
            localStorage.setItem("user", JSON.stringify(payload))
            state.user = payload
        })
    }
})

export const { checkUser, logout } = userSlice.actions

export default userSlice.reducer