import logoImg from "../../assets/Logo.png";
import "./Loader.scss";

const Loader = () => {
  return (
    <div className="loader">
      <img className="loader-img" src={logoImg} alt="" />
    </div>
  );
};

export default Loader;
